


























import { defineComponent } from '@vue/composition-api';
import { useResultStore } from './store';
import { Result } from './api';
import DetailCard from './DetailCard.vue';
import Descriptions from '@/components/Descriptions.vue';
import Recommend from '@/components/Recommend.vue';
import RequiredContact from '@/components/RequiredContact.vue';

export default defineComponent({
  components: { DetailCard, Descriptions, Recommend, RequiredContact },
  setup(props, { root }) {
    const { state } = useResultStore();
    if (state.current === null) {
      root.$router.replace({ name: 'Result' });
    }

    return { detail: state.current };
  },
});
